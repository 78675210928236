export default [
    {
        header: '合规管理',
        resource: 'header-1',
        action: 'manage',
    },

    {
        title: '隐私框架',
        icon: 'EditIcon',
        children: [{
            title: '隐私组织',
            route: 'enterprise-organization',
            resource: 'enterprise',
            action: 'manage',
        },
        {
            title: '隐私政策',
            route: 'enterprise-policy',
            resource: 'enterprise',
            action: 'manage',
        },
        {
            title: '隐私声明',
            route: 'enterprise-statement',
            resource: 'enterprise',
            action: 'manage',
        },
        {
            title: '制度流程',
            route: 'enterprise-statement',
            resource: 'enterprise',
            action: 'manage',
        },
        ]
    },

    {
        title: '数据管理',
        icon: 'MapIcon',        
        children: [
            {
                title: '数据资产管理',
                route: 'datamap-asset',
                resource: 'datamap',
                action: 'manage',
            },
            {
                title: '隐私字段清单',
                route: 'datamap-fields',
                resource: 'datamap',
                action: 'manage',
            },
            {
                title: '采集流程管理',
                route: 'datamap-process',
                resource: 'datamap',
                action: 'manage',
            },
            {
                title: '数据地图填报',
                route: 'datamap-form',
                resource: 'datamap',
                action: 'manage',
            },
            {
                title: '数据地图报表',
                route: 'datamap-analysis',
                resource: 'datamap',
                action: 'manage',
            },
        ]
    },

    {
        title: '合规评估',
        icon: 'CpuIcon',
        route: 'product',
        resource: 'product',
    },

    {
        title: '风险态势',
        icon: 'KeyIcon',       
        children: [
            {
                title: 'PIA数据字典',
                route: 'pia-dictionary',
                resource: 'pia',
                action: 'manage',
            },
            {
                title: 'PIA报表管理',
                route: 'pia-analysis',
                resource: 'pia',
                action: 'manage',
            },
            {
                title: 'PIA启动管理',
                route: 'pia-process',
                resource: 'pia',
                action: 'manage',
            },
            {
                title: 'PIA填报',
                route: 'pia-form',
                resource: 'pia',
                action: 'manage',
            },
        ]
    },

    {
        header: '合规治理',
        resource: 'header-1',
        action: 'manage',
    },

    {
        title: '同意偏好',
        icon: 'CheckSquareIcon',       
        children: [
            {
                title: '法律合规管理',
                route: 'compliance-list', 
                resource: 'compliance',
                action: 'manage',
            },
            {
                title: '合规问卷填报',
                route: 'compliance-process', 
                resource: 'compliance',
                action: 'manage',
            },
        ]
    },

   

    {
        title: 'Cookie管理',
        icon: 'ClipboardIcon',
        children: [
            {
                title: '模版管理',
                route: 'cookies-template',
                resource: 'cookies',
                action: 'manage',
            },
            {
                title: '网站配置',
                route: 'cookies-website',
                resource: 'cookies',
                action: 'manage',
            },
        ]     
        
    },

    // 合规培训
    
     {
        title: '第三方合规管理',
        icon: 'UserCheckIcon',
        children: [
            {
                title: '渠道管理',
                route: 'cpm-channel',
                resource: 'cpm',
                action: 'manage',
            },
            {
                title: '统计分析',
                route: 'cpm-statistic',
                resource: 'cpm',
                action: 'manage',
            },
        ]        
    },

    {
        title: '隐私设计管理',
        icon: 'BookIcon',
        children: [
            {
                title: '法律库',
                icon: 'BookIcon',
                route: 'library-law',
                resource: 'library-law',
                action: 'manage',
            },
            {
                title: '案例库',
                icon: 'BookIcon',
                route: 'library-case',
                resource: 'library-case',
                action: 'manage',
            },
            {
                title: '数据字典',
                icon: 'BookIcon',
                route: 'library-dictionary',
                resource: 'library-dictionary',
                action: 'manage',
            },
        ]
    },

    {
        title: '应急响应管理',
        icon: 'BookOpenIcon',        
        children: [{
            title: '培训课程',
            route: 'training-course',
            action: 'manage',
            resource: 'training',
        },
        {
            title: '法律法规',
            route: 'training-laws',
            action: 'manage',
            resource: 'training',
        },
        {
            title: '经典案例',
            route: 'training-cases',
            action: 'manage',
            resource: 'training',
        },
        ]
    },


    {
        header: '运营管理',
        resource: 'header-2',
        action: 'manage',
    },

    {
        title: '企业管理',
        icon: 'PieChartIcon',
        children: [{
            title: '统计分析',
            route: 'statistics',
            resource: 'statistics',
            action: 'manage',
        },
        {
            title: '大屏展示',
            route: 'largescreen',
            resource: 'largescreen',
            action: 'manage',
        },
        ]
    },    

    {
        title: '系统配置',
        icon: 'SettingsIcon',
        children: [
            {
                title: '用户管理',
                icon: 'UsersIcon',
                route: 'user',
                resource: 'user',
                action: 'manage',
            },
            {
                title: '部门管理',
                icon: 'UsersIcon',
                route: 'organization',
                resource: 'organization',
                action: 'manage',
            },
            {
                title: '角色管理',
                icon: 'UsersIcon',
                route: 'role',
                resource: 'role',
                action: 'manage',
            },           
            {
                title: '通知管理',
                route: 'notify',
                resource: 'notify',
                action: 'manage',
            },
            {
                title: '日志管理',
                route: 'log',
                resource: 'log',
                action: 'manage',
            },
            {
                title: '邮件设置',
                route: 'smtp',
                resource: 'email',
                action: 'manage',
            },
        ]
    },

]